<template>
    <div class="all-news">
        <div class = "news_banner">{{ $t("message.news.title") }}</div>
        <div class = "sub_title">{{ $t("message.news.hot-views") }}</div>
        <div class = "hot_views"> 
            <div class="part1" @click="goNews(recommendMemo[0])">
                <img 
                    loading="lazy" 
                    :src="`${this.recommendMemo[0].articleCoverUrl}`"
                    alt=""
                    />
                <div class="info"><div class="title"> {{ this.recommendMemo[0].articleTitle }}</div></div>
            </div>
            <div class="part2" @click="goNews(recommendMemo[1])">
                <img 
                    loading="lazy" 
                    :src="`${this.recommendMemo[1].articleCoverUrl}`"
                    alt=""
                    />
                <div class="info"><div class="title"> {{ this.recommendMemo[1].articleTitle }}</div></div>
            </div>
            <div class="part3" @click="goNews(recommendMemo[2])">
                <img 
                    loading="lazy" 
                    :src="`${this.recommendMemo[2].articleCoverUrl}`"
                    alt=""
                    />
                <div class="info"><div class="title"> {{ this.recommendMemo[2].articleTitle }}</div></div>
            </div>
            <div class="part4" @click="goNews(recommendMemo[3])">
                <img 
                    loading="lazy" 
                    :src="`${this.recommendMemo[3].articleCoverUrl}`"
                    alt=""
                    />
                <div class="info"><div class="title"> {{ this.recommendMemo[3].articleTitle }}</div></div>
            </div>
        </div>
        <div class = "sub_title">{{ $t("message.news.all-views") }}</div>
        <div class="gallery" ref="gallery" id="gallery">
            <div
                class="item"
                v-for="(item, index) in this.allNews"
                :key="index"
            >
                <div class = "item-main" @click="goNews(item)">
                    <div
                        class="image"
                    >
                        <img loading="lazy" :src="`${item.articleCoverUrl}`" />
                    </div>
                    <div class="content">
                        <p
                            class="title"
                        >
                            {{ item.articleTitle }}
                        </p>
                        <div class="date">{{ item.createTime | formatDate }}</div>
                        <div class = "main-body"> {{ item.articleContent}}</div>
                    </div>
                </div>
                
                <div class = "functions flex-row">
                    <div class="button " @click="goNews(item)">
                        {{ $t("message.news.button1") }}
                    </div>
                    <div class="icons flex-row">
                        <ShareNetwork
                            network="facebook"
                            title="I'd like to share this news with you."
                            :url= item.currentUrl 
                        >
                            <div class="icon-1 icon">
                                <i
                                    style="font-size: 1.7rem"
                                    class="iconfont iconfacebook"
                                ></i>
                            </div>
                        </ShareNetwork>   
                        <ShareNetwork
                            network="twitter"
                            title="I'd like to share this news with you."
                            :url=item.currentUrl 
                        >
                            <div class="icon-3 icon">
                                <i
                                    style="font-size: 1.8rem"
                                    class="iconfont icontweeter"
                                ></i>
                            </div>
                        </ShareNetwork>
                        <ShareNetwork
                            network="weibo"
                            title="I'd like to share this news with you."
                            :url=item.currentUrl 
                        >
                            <div class="icon-2 icon">
                                <i
                                    style="font-size: 2.3rem"
                                    class="iconfont icon-weibo"
                                ></i>
                            </div>
                        </ShareNetwork>
                    </div>
                </div>
            </div>
        </div>

        <v-page
            class="pagination"
            ref="pagination"
            style= "
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 50px;
            "
            :total-row="totalRow"
            :page-size-menu="false"
            :pageSize="9"
            :info="false"
            :first="false"
            :last="false"
            :page-number="false"
            @page-change="pageChange"
        ></v-page>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDate } from '@/common/date.js' // 在组件中引用date.js
export default {
    name: "all-news",

    data() {
        return {
            allNews: [],
            testContent: "",
            recommendMemo: [],
            totalRow: 0,
            currentLang: 1,
            backupMemo: [],
        };
    },

    async created() {
        await this.initGallery(this.currentLang, 1);
        await this.getAllNews(this.currentLang, 1, 9);
        this.backupMemo = this.allNews;
        // await this.getRecommendMemo(1, 10, 1);
    },

    mounted() {
        if (this.$store.state.lang === "zh") {
            this.currentLang = 0;
        } else {
            this.currentLang = 1;
        }
    },

    computed: {
        ...mapState(["lang"]),
    },

    filters: {
        formatDate(time) {
            var date = new Date(time);
            return formatDate(date, 'yyyy-MM-dd');
        }
    },

    watch: {
        lang(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue === "zh") {
                    this.currentLang = 0;
                    this.getAllNews(this.currentLang, 1, 9);
                    // this.getRecommendMemo(1, 10, this.currentLang);
                    
                } else {
                    this.currentLang = 1;
                    this.getAllNews(this.currentLang, 1, 9);
                    // this.getRecommendMemo(1, 10, this.currentLang);
                    
                }
            }
        },
    },

    methods: {
        back: function () {
            this.$router.go(-1);
        },

        goNews: function (item) {
            this.$router.push("/news/?id=" + item.id);
            window.scrollTo(0, 0);
        },

        pageChange(pInfo) {
            this.getAllNews(this.currentLang, pInfo.pageNumber, pInfo.pageSize-1);
        },

        async initGallery(languageType, pageNum) {
            let response = await this.$http.get(
                `${this.$url.memo.url_memo_public_info}?pageNum=${pageNum}&pageSize=1&types=2`
            );
            this.totalRow = response.data.publicList.total;
            // console.log(this.totalRow)
        },

        async getAllNews(languageType, pageNum, pageSize) {
            let response = await this.$http.get(
                `${this.$url.memo.url_memo_public_info}?pageNum=${pageNum}&pageSize=${pageSize}&types=2`
            );
            this.allNews = response.data["publicList"].list;
            this.recommendMemo = response.data["recommendList"];
            // console.log(this.allNews)
            // console.log(this.recommendMemo)

            if (this.allNews.length !== 0) {
                for (let index = 0; index < this.allNews.length && index<10; index++) {
                    if (languageType===1) {
                        this.allNews[index].articleTitle = this.allNews[index].articleTitleEn;
                        this.allNews[index].articleContent = this.allNews[index].articleContentEn;
                    }
                    else {
                        this.allNews[index].articleTitle = this.allNews[index].articleTitleCn;
                        this.allNews[index].articleContent = this.allNews[index].articleContentCn;
                    }  
                    if (this.allNews[index].articleContent !== null) {
                        this.allNews[index].articleContent = this.allNews[index].articleContent.replace(/<[^>]+>/g, '')
                    }
                   this.allNews[index].currentUrl = "http://www.mcc.sg/news/?id="+this.allNews[index].id;
                }
            }      
            // console.log(this.allNews);
            if (this.recommendMemo.length !== 0) {
                for (let index = 0; index < this.recommendMemo.length && index<10; index++) {
                    if (languageType===1) {
                        this.recommendMemo[index].articleTitle = this.recommendMemo[index].articleTitleEn;
                        this.recommendMemo[index].articleContent = this.recommendMemo[index].articleContentEn;
                    }
                    else {
                        this.recommendMemo[index].articleTitle = this.recommendMemo[index].articleTitleCn;
                        this.recommendMemo[index].articleContent = this.recommendMemo[index].articleContentCn;
                    }  
                    if (this.recommendMemo[index].articleContent !== null) {
                        this.recommendMemo[index].articleContent = this.recommendMemo[index].articleContent.replace(/<[^>]+>/g, '')
                    }
                   this.recommendMemo[index].currentUrl = "http://www.mcc.sg/news/?id="+this.recommendMemo[index].id;
                }
            }  
            console.log(this.allNews)
            console.log(this.recommendMemo)

        },

        async getRecommendMemo(pageNum, pageSize, languageType) {
            let response = await this.$http.get(
                `${this.$url.memo.url_memo_recommend}?pageNum=${pageNum}&pageSize=${pageSize}&types=2`
            );
            let recommendMemoRaw = response.data.list;
            
            var news_list = new Array();
            for (let index = 0; index < recommendMemoRaw.length; index++) {
                news_list.push(recommendMemoRaw[index].id)
                if (languageType===1) {
                    recommendMemoRaw[index].articleTitle = recommendMemoRaw[index].articleTitleEn;
                    recommendMemoRaw[index].articleContent = recommendMemoRaw[index].articleContentEn;
                }
                else {
                    recommendMemoRaw[index].articleTitle = recommendMemoRaw[index].articleTitleCn;
                    recommendMemoRaw[index].articleContent = recommendMemoRaw[index].articleContentCn;
                }  
            }
            if (recommendMemoRaw.length < pageSize) {
                for (let news = 0; (news < this.backupMemo.length && news <= 4); news++) {
                    if (news_list.indexOf(this.backupMemo[news].id)) {
                        news_list.push(this.backupMemo[news].id)
                        recommendMemoRaw.push(this.backupMemo[news])
                    }
                }
            }
            this.recommendMemo = recommendMemoRaw;
            // console.log(this.recommendMemo)
        },
    },
};
</script>

<style lang="scss">
@mixin styling($unit-size) {
    .all-news {
        padding: $unit-size * 30 $unit-size * 83 $unit-size * 80 $unit-size * 83;

        .news_banner {
            padding-top: 25*$unit-size;
            width: 1200 * $unit-size;
            height: 75 * $unit-size;
            border-radius: 15px;
            line-height: 50 * $unit-size;
            font-size: 36 * $unit-size;
            font-weight: 500;
            text-align: center;
            vertical-align: middle;
            color: white;
            background-image: url('../../assets/imgs/home/Events_title.png');
            background-repeat: no-repeat;
            background-size: 100% auto;
        }

        .sub_title {
            font-size: 30 * $unit-size;
            margin: 30 * $unit-size 0 22 * $unit-size 0;
            font-weight: 550;
        }

        .hot_views{
            width: $unit-size * 1200;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, 214*$unit-size);
            row-gap: 30*$unit-size;
            column-gap: 30*$unit-size;
            cursor: pointer;
            img {
                border-radius: 15px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-color: rgba(0,0,0,.05);
                border-width: 1px;
                border-style: solid;
            }
            .info {
                
                position: relative;
                width: 100%;
                margin-top: -86*$unit-size;
                height: 86 * $unit-size;      
                background-image: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,.7) 100%);
                color: rgb(255, 255, 255);
                text-align: left;

                border-radius: 0 0 15px 15px;
                .title{
                    margin: 0 ($unit-size * 16) ($unit-size * 10) ($unit-size * 16);

                    line-height: 18 * $unit-size;
                    font-size: 16 * $unit-size;
                    font-weight: 500;
                    max-height: 18 * 2 * $unit-size;
                    overflow: hidden;
                    align-items: center;
                    bottom: 0;
                    position: absolute;
                    

                }               
            }
            .part1{
                grid-row: 1 / 3;
                grid-column: 1 / 3;
            }

            .part2{
                grid-row: 1 / 2;
                grid-column: 3 / 5;
            }
            .part3{
                grid-row: 2;
                grid-column: 3;
            }
            .part4{
                grid-row: 2;
                grid-column: 4;
            }

        }

        .gallery {     
            width: 1200*$unit-size;
            display: grid;
            grid-template-columns: repeat(3, 380*$unit-size);
            grid-template-rows: repeat(3, 415*$unit-size);
            row-gap: 30*$unit-size;
            column-gap: 30*$unit-size;
            .item {   
                // background-color: yellow;
                border-width: 1px;
                .item-main{
                    cursor: pointer;
                    // background-color: red;
                    .image {
                        width: 100%;
                        height: 230*$unit-size;
                        img {
                            border-radius: 10px 10px 10px 10px;
                            width: 100%;
                            height: 100%;
                            object-fit:cover;
                            border-color: rgba(0,0,0,.05);
                            border-width: 1px;
                            border-style: solid;
                        }
                    }
                    .content {
                        width: 100%;

                        // background-color: red;
                        text-align:left;
                        font: normal normal 2.5rem Raleway;
                        p.title {
                            margin-top: 13 * $unit-size;                           
                            font-size: 18 * $unit-size;
                            line-height: 25 * $unit-size;
                            height: 25 * $unit-size;
                            font-weight: 800;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            
                        }
                        div.main-body {
                            margin-top: 8 * $unit-size;
                            width: 100%;
                            height:60px ;
                            // background-color: green;
                            // height: 22 * 4 * $unit-size;
                            font-size: 16 * $unit-size;
                            line-height: 22 * $unit-size;
                            font-weight: 400;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: rgba(0,0,0,0.65);
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp:3;
                        }

                        .date {
                            //background-color: green;
                            margin-top: 8 * $unit-size;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-size: 14 * $unit-size;
                            font-weight: 400;
                            width: 90%;
                            color:rgba(0,0,0,0.45);
                        }
                    }
                }
                    
            }
            .functions {
                justify-content: space-between;
                margin-top: 16 * $unit-size;
                a {
                    text-decoration: none !important;
                    outline: none;
                }
                .button {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    cursor: pointer;
                    width: 96 * $unit-size;
                    height: 34 * $unit-size;
                    background-color: rgba(74, 145, 242, 1);
                    border-radius: 17*$unit-size;
                    font-size: 14 * $unit-size;
                    line-height: 30 * $unit-size;
                    color: white;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                }
                .icon {
                    margin-top: 10 * $unit-size;
                    // margin-left: 200 * $unit-size;
                    cursor: pointer;
                    color: black;
                }
                .icon-2 {
                    margin-left: 10.28 * $unit-size;
                    margin-top: 8.5 * $unit-size;
                }
                .icon-3 {
                    margin-left: 12.28 * $unit-size;
                }       
            }
        }
    }
}
@media (max-width: 100vw) {
    @include styling($unit-size: 100vw/1366);
}
</style>